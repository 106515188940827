import styled from "styled-components";
import {FormFieldWrapper} from "../../../components/FormFields/FormFields";
import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {PortalDateTimeField} from "../../../components/PortalFormFields/PortalDateTimeField/PortalDateTimeField";
import dayjs from "dayjs";
import {PortalSelectField} from "../../../components/PortalFormFields/PortalSelectField/PortalSelectField";
import {FilterButtonBase} from '../../../components/FilterButtonBase/FilterButtonBase';
import {ControlledPortalSelectField} from '../../../components/PortalFormFields/PortalSelectField/ControlledPortalSelectField';

const StyledFormFieldWrapper = styled(FormFieldWrapper)`
    min-width: 0;
`;

export function FilterButton({showInvitedFilters, healthInsuranceData, locationData, onAfterSubmit}) {
    const [isOpen, setIsOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    // Set the unappliedFilters initially to current searchParams (retreived from searchParams
    const [unappliedFilters, setUnappliedFilters] = useState({
        registeredFrom: searchParams.get("registeredFrom"),
        registeredUntil: searchParams.get("registeredUntil"),
        invitedFrom: searchParams.get("invitedFrom"),
        invitedUntil: searchParams.get("invitedUntil"),
        healthInsurance: searchParams.getAll("healthInsurance"),
        location: searchParams.getAll("location"),
        hasEmail: searchParams.get("hasEmail"),
    });

    // Keep unappliedFilters correct when searchParams change (when deleting in AppliedFilterList)
    useEffect(() => {
        setUnappliedFilters({
            registeredFrom: searchParams.get("registeredFrom"),
            registeredUntil: searchParams.get("registeredUntil"),
            invitedFrom: searchParams.get("invitedFrom"),
            invitedUntil: searchParams.get("invitedUntil"),
            healthInsurance: searchParams.getAll("healthInsurance"),
            location: searchParams.getAll("location"),
            hasEmail: searchParams.get("hasEmail"),
        })
    }, [searchParams]);

    function handleUnappliedFiltersChange(key, value) {
        setUnappliedFilters({
            ...unappliedFilters,
            [key]: value
        })
    }

    function handleApply() {
        // Set search params optionally
        const newParams = {
            ...(unappliedFilters.registeredFrom ? {registeredFrom: unappliedFilters.registeredFrom} : {}),
            ...(unappliedFilters.registeredUntil ? {registeredUntil: unappliedFilters.registeredUntil} : {}),
            ...(unappliedFilters.invitedFrom ? {invitedFrom: unappliedFilters.invitedFrom} : {}),
            ...(unappliedFilters.invitedUntil ? {invitedUntil: unappliedFilters.invitedUntil} : {}),
            ...(unappliedFilters.healthInsurance ? {healthInsurance: unappliedFilters.healthInsurance} : {}),
            ...(unappliedFilters.location ? {location: unappliedFilters.location} : {}),
            ...(unappliedFilters.hasEmail ? {hasEmail: unappliedFilters.hasEmail} : {}),
        }
        setSearchParams(newParams, {replace: true, preventScrollReset: true});
        onAfterSubmit();
        setIsOpen(false);
    }

    // Select fields options
    const healthInsuranceFilterOptions = healthInsuranceData?.map(item => ({value: item.id, label: item.name})) ?? [];
    const locationFilterOptions = locationData?.map(item => ({value: item.id, label: item.name})) ?? [];

    const hasEmailFilterOptions = [
        { value: "", label: "Alle" },
        { value: "1", label: "Met e-mailadres" },
        { value: "0", label: "Zonder e-mailadres" },
    ];

    return (
        <FilterButtonBase isOpen={isOpen} setIsOpen={setIsOpen} handleApply={handleApply}>
            <StyledFormFieldWrapper
                id="registeredFrom"
                label="Aangemeld vanaf"
            >
                <PortalDateTimeField
                    placeholder="Kies een datum"
                    minDate={null}
                    hideTime={true}
                    defaultValue={unappliedFilters?.registeredFrom ? dayjs(unappliedFilters.registeredFrom).format("DD-MM-YYYY") : ""}
                    onChange={(date) => handleUnappliedFiltersChange("registeredFrom", date ? dayjs(date).format("YYYY-MM-DD") : "")}
                />
            </StyledFormFieldWrapper>

            <StyledFormFieldWrapper
                id="registeredUntil"
                label="Aangemeld vóór"
            >
                <PortalDateTimeField
                    placeholder="Kies een datum"
                    minDate={null}
                    hideTime={true}
                    defaultValue={unappliedFilters?.registeredUntil ? dayjs(unappliedFilters.registeredUntil).format("DD-MM-YYYY") : ""}
                    onChange={(date) => handleUnappliedFiltersChange("registeredUntil", date ? dayjs(date).format("YYYY-MM-DD") : "")}
                />
            </StyledFormFieldWrapper>

            { showInvitedFilters && <>
                <StyledFormFieldWrapper
                    id="invitedFrom"
                    label="Uitgenodigd vanaf"
                >
                    <PortalDateTimeField
                        placeholder="Kies een datum"
                        minDate={null}
                        hideTime={true}
                        defaultValue={unappliedFilters?.invitedFrom ? dayjs(unappliedFilters.invitedFrom).format("DD-MM-YYYY") : ""}
                        onChange={(date) => handleUnappliedFiltersChange("invitedFrom", date ? dayjs(date).format("YYYY-MM-DD") : "")}
                    />
                </StyledFormFieldWrapper>
            </>}

            { showInvitedFilters && <>
                <StyledFormFieldWrapper
                    id="invitedUntil"
                    label="Uitgenodigd vóór"
                >
                    <PortalDateTimeField
                        placeholder="Kies een datum"
                        minDate={null}
                        hideTime={true}
                        defaultValue={unappliedFilters?.invitedUntil ? dayjs(unappliedFilters.invitedUntil).format("DD-MM-YYYY") : ""}
                        onChange={(date) => handleUnappliedFiltersChange("invitedUntil", date ? dayjs(date).format("YYYY-MM-DD") : "")}
                    />
                </StyledFormFieldWrapper>
            </> }

            <StyledFormFieldWrapper
                id="healthInsurance"
                label="Zorgverzekeraar"
            >
                <ControlledPortalSelectField
                    isMulti={true}
                    placeholder="Alle"
                    options={healthInsuranceFilterOptions}
                    onChange={(option) => handleUnappliedFiltersChange("healthInsurance", option?.filter(o => o?.value?.length > 0).map(o => o?.value?.toLowerCase()))}
                    defaultValue={healthInsuranceFilterOptions?.filter(o => unappliedFilters?.healthInsurance?.includes(o?.value?.toLowerCase()))}
                />
            </StyledFormFieldWrapper>

            <StyledFormFieldWrapper
                id="location"
                label="Locatie"
            >
                <ControlledPortalSelectField
                    isMulti={true}
                    placeholder="Alle"
                    options={locationFilterOptions}
                    onChange={(option) => handleUnappliedFiltersChange("location", option?.filter(o => o?.value?.length > 0).map(o => o.value))}
                    defaultValue={locationFilterOptions?.filter(o => unappliedFilters?.location?.includes(o.value))}
                />
            </StyledFormFieldWrapper>

            <StyledFormFieldWrapper
                id="hasEmail"
                label="Patiënten"
            >
                <PortalSelectField
                    options={hasEmailFilterOptions}
                    onChange={(option) => handleUnappliedFiltersChange("hasEmail", option?.value)}
                    defaultValue={hasEmailFilterOptions?.find(item => item?.value === unappliedFilters?.hasEmail) ?? hasEmailFilterOptions[0]}
                />
            </StyledFormFieldWrapper>
        </FilterButtonBase>
    );
}