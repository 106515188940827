import * as Tooltip from '@radix-ui/react-tooltip';
import {Heading3} from '../Heading/Heading';
import {Text} from '../Text/Text';
import dayjs from 'dayjs';
import {TimelineFlag} from './TimelineFlag';
import React from 'react';
import styled, {css} from 'styled-components';
import {getBorderColor, getBorderStyle} from './timelineHelpers';
import {ConditionalWrap} from '../Utils/Utils';

dayjs.locale('nl');

const InnerAppointment = styled.div`
    border: 2px ${({$status, $isInThePast}) => getBorderStyle($status, $isInThePast)} ${({$category}) => getBorderColor($category)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    border-radius: 8px;
    width: ${({$duration}) => $duration === 5 ? "30px" : $duration === 15 ? "40px" : $duration === 30 ? "50px" : $duration === 45 ? "60px" : "60px"};
    ${({$status}) => ($status === "Cancelled" || $status === "Noshow") && "opacity: 0.3;"};
`;

const StyledTooltipContent = styled(Tooltip.Content)`
    border-radius: 10px;
    padding: 14px 20px 14px 14px;
    color: var(--color-dark);
    background: var(--color-white);
    border: 1px solid var(--color-grey-20);
    z-index: 99;
    min-width: 230px;
    max-width: 400px;
    font-size: var(--fs-info-tooltip);
    line-height: 1.15;
    font-weight: var(--fw-regular);
    pointer-events: none;
    box-shadow: var(--box-shadow), 2px 2px 50px 0 rgba(0, 0, 0, .05);
`;

const InnerTooltip = styled.div`
    ${({$status}) => ($status === "Cancelled" || $status === "Noshow") && "opacity: .3"};
`;

const InnerContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
`;

const DateTime = styled.div`
    display: flex;
`

const TooltipFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const TooltipStatus = styled(Text).attrs({
    $noMargin: true,
    $align: "center",
    $bold: true
})`
    flex-shrink: 0;
    padding: 8px 16px;
    border-radius: 999px;
    ${({$isNotProcessed}) => $isNotProcessed ? css`
        border: 2px solid var(--color-unprocessed);
        background-color: var(--color-unprocessed);
        color: var(--color-white);
    ` : css`
        border: 2px ${({$status, $isInThePast}) => getBorderStyle($status, $isInThePast)} ${({$category}) => getBorderColor($category)};
        color: ${({$category}) => getBorderColor($category)};
    `};
`;

const AppointmentText = styled(Text).attrs({
    $noMargin: true,
    $align: "center",
    $small: true
})`
    margin: auto 0;
    font-weight: var(--fw-extra-bold);
    color: ${({$category}) => getBorderColor($category)};
`;

const StyledTooltipArrow = styled.svg`
    fill: var(--color-white);
    stroke: var(--color-grey-20);
    stroke-width: 1px;
    stroke-dasharray: 0 28 28;
    position: relative;
    top: -1px;
`;

export const TimelineAppointment = ({appointment, isInThePast, isNotProcessed}) => {
    // TODO: Dit moet waarschijnlijk obv. appointment.category 
    const isDiagnosticsDay = appointment.title === "Diagnostiekdag"

    function calculateDuration(appointment) {
        if (appointment.category === "E-health") {
            return 5
        }

        const targetDuration = (appointment.actualDuration && appointment.actualDuration > 0)
            ? (Math.abs(appointment.actualDuration - appointment.duration) >= 15
                ? appointment.actualDuration
                : appointment.duration)
            : appointment.duration;

        const allowedDurations = [5, 15, 30, 45];
        return allowedDurations.reduce((prev, curr) =>
            Math.abs(curr - targetDuration) < Math.abs(prev - targetDuration) ? curr : prev
        );
    }

    return (
        <Tooltip.Provider delayDuration={100} disableHoverableContent>
            <Tooltip.Root>
                <Tooltip.Trigger asChild>
                    <InnerAppointment
                        $isInThePast={isInThePast}
                        $status={appointment.status}
                        $duration={calculateDuration(appointment)}
                        $category={appointment.category}
                    >
                        {appointment.category === "E-health" ? (
                            <AppointmentText $category={appointment.category}>e</AppointmentText>
                        ) : isDiagnosticsDay ? (
                            <AppointmentText $category={appointment.category}>D</AppointmentText>
                        ) : (appointment.count || appointment?.["length"]) ? (
                            <AppointmentText $category={appointment.category}>{appointment.count}{appointment?.["length"] ? (` / ${appointment?.["length"] === -1 ? "?" : appointment?.["length"]}`) : ""}</AppointmentText>
                        ) : null}
                    </InnerAppointment>
                </Tooltip.Trigger>

                <Tooltip.Portal>
                    <StyledTooltipContent
                        side="bottom"
                        align="start"
                        alignOffset={-32}
                        sideOffset={-10}
                        arrowPadding={10}
                        avoidCollisions
                        style={{ zIndex: 1000 }}
                    >
                        <InnerTooltip $status={appointment.status}>
                            <InnerContent>
                                <Heading3 $noMargin>
                                    <ConditionalWrap
                                        condition={appointment.status === "Cancelled" || appointment.status === "Noshow"}
                                        wrap={children => <s>{children}</s>}
                                    >
                                        {appointment.title}
                                    </ConditionalWrap>
                                </Heading3>
                                <DateTime>
                                    <Text $small $bold $noMargin>{dayjs(appointment.date).format('D MMM YYYY')},</Text>
                                    <Text $small $noMargin>&nbsp;{dayjs(appointment.start).format('HH:mm')} - {dayjs(appointment.end).format('HH:mm')}</Text>
                                </DateTime>
                                {(appointment.practitioners?.length > 0 && !isDiagnosticsDay) ? <Text $small $noMargin>{appointment.practitioners?.join(", ")}</Text> : null}
                            </InnerContent>
                            <TooltipFooter>
                                <TooltipStatus
                                    $isInThePast={isInThePast}
                                    $status={appointment.status}
                                    $category={appointment.category}
                                    $isNotProcessed={isNotProcessed}
                                >
                                    {isNotProcessed ? "Niet verwerkt" : appointment.status === "Cancelled" ? "Geannuleerd" : appointment.status === "Noshow" ? "No-show" : isInThePast ? "Verwerkt" : appointment.status === "Booked" ? "Gepland" : ""}
                                </TooltipStatus>
                                {(appointment.count || appointment?.["length"]) &&
                                    <Text $small $noMargin>{appointment.count ? `#${appointment.count}` : ""}{appointment?.["length"] ? (` / ${appointment?.["length"] === -1 ? "?" : appointment?.["length"]}`) : ""}</Text>
                                }
                            </TooltipFooter>
                        </InnerTooltip>

                        <Tooltip.Arrow asChild>
                            <StyledTooltipArrow width="40" height="16" viewBox="0 0 40 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 5.46891e-07C11 5.46891e-07 42.3327 6.70605e-05 39.8611 5.59313e-06C37.3896 -5.58742e-05 34.918 -5.61379e-05 31.2107 2.99994C28.7209 5.01475 22.3293 10.1869 18.3998 13.3667C17.0921 14.4249 15.1457 13.491 15.1457 11.8088L15.1457 8C15.1457 8 15.1457 4.50001 15.1457 2.00001C15.1457 -6.29329e-05 11 5.46891e-07 11 5.46891e-07Z" fill="white"/>
                            </StyledTooltipArrow>
                        </Tooltip.Arrow>
                    </StyledTooltipContent>
                </Tooltip.Portal>
            </Tooltip.Root>

            {appointment.status === "Noshow" && <TimelineFlag type="noShow" />}
            {isNotProcessed && <TimelineFlag type="notProcessed" />}
        </Tooltip.Provider>
    );
}