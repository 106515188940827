import styled from 'styled-components';
import {Text} from '../Text/Text';
import React from 'react';
import {ConditionalWrap} from '../Utils/Utils';

export const InfoListWrapper = styled.div`
    background-color: var(--color-white);
    border-radius: 16px;
    box-shadow: var(--box-shadow);
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
`;

export const InfoList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

const InfoListItemLabel = styled(Text).attrs({
    $bold: true,
    $noMargin: true
})`
    margin-bottom: 6px;
`;

const InfoListItemText = styled(Text).attrs({
    $noMargin: true
})`
    min-height: 20px;
`;

export const InfoListItem = ({className, label, children, wrapInText = true}) => {
    return (
        <div className={className}>
            <InfoListItemLabel>{label}</InfoListItemLabel>
            <ConditionalWrap
                condition={wrapInText}
                wrap={children => <InfoListItemText>{children}</InfoListItemText>}
            >
                {children}
            </ConditionalWrap>
        </div>
    );
}