import {createGlobalStyle} from "styled-components";
import normalize from "styled-normalize";

const GlobalStyle = createGlobalStyle`
    ${normalize}
    * {
        box-sizing: border-box;
    }

    :root {
        // Primitives
        --color-white: #fff;
        --color-black: #000;
        --color-dark: #2D2A26;
        --color-dark-rgb: 45, 42, 38;

        --color-blue-50: #304296;
        --color-blue-50-rgb: 48, 66, 150;
        --color-blue-60: #2b3c87;

        --color-grey-10: #FAFAFA;
        --color-grey-10-rgb: 250, 250, 250;
        --color-grey-20: #EFEFEF;
        --color-grey-20-rgb: 239, 239, 239;
        --color-grey-90: #2D2A26;
        --color-grey-90-rgb: 45, 42, 38;
        
        --color-red: #CA3030;

        // Tokens
        --color-heading: var(--color-dark);
        --color-text: var(--color-dark);
        --color-text-alpha-50: rgba(var(--color-dark-rgb), .5);
        --color-primary: var(--color-blue-50);
        --color-primary-hover: var(--color-blue-60);
        --color-primary-transparent: rgba(var(--color-blue-50-rgb), .05);
        --color-primary-light: #EAECF5;
        --color-primary-light-hover: #E5E8F3;
        --color-linear-gradient: linear-gradient(130deg, rgba(242, 242, 242, 1) 0%, rgba(255, 255, 255, 1) 100%);
        --color-background: var(--color-grey-10);
        --color-content-background: var(--color-grey-10);
        --color-table-odd-row: var(--color-primary-transparent);
        --color-table-odd-row-disabled: var(--color-grey-20);
        --color-table-title: #171717;
        --color-table-text: #374151;
        --color-secondary-button: #31314C;
        --color-secondary-button-hover: #232336;
        --color-required: #CD463C;
        --color-input-background: var(--color-grey-20);
        --color-slider: #939290;
        --color-tab: var(--color-grey-20);
        --color-divider: var(--color-grey-20);
        --color-error: #D71212;
        --color-remaining-block-green: #309634;
        --color-remaining-block-orange: #DB6F21;
        --color-remaining-block-red: var(--color-red);
        --color-toggle-unchecked: rgba(181, 181, 182, 1);
        --color-toggle-disabled: rgba(181, 181, 182, 0.6);
        --color-toggle-checked: var(--color-blue-50);
        --color-table-of-contents-active: var(--color-blue-50);
        --color-table-of-contents-hover: var(--color-primary-transparent);
        --color-aside-item-hover: var(--color-primary-light);
        --color-aside-item-accent: var(--color-primary-light);
        --color-portal-button: var(--color-grey-20);
        --color-portal-button-hover: #dfdfdf;
        --color-portal-select-field-hover: var(--color-primary-transparent);
        --color-environment-default: #14b9ff;
        --color-environment-dev: #c084fc;
        --color-environment-test: #facc15;
        --color-environment-acceptance: #4ade80;
        --color-impersonate: #FF4E4E;
        --color-alert: var(--color-red);
        --color-warning-error: var(--color-red);
        --color-no-show: #F2F2F2;
        --color-unprocessed: #CD463C;
        --color-timeline-medicatieconsult: #F2BB70;
        --color-timeline-cgt: #6CB675;
        --color-timeline-slaaptherapie: #B4A74E;
        --color-timeline-psychoeducatie: #E98261;
        --color-timeline-default: var(--color-dark);

        // Font properties
        --font: 'Mulish', sans-serif;
        --fw-regular: 400;
        --fw-medium: 500;
        --fw-semi-bold: 600;
        --fw-bold: 700;
        --fw-extra-bold: 800;

        // Font sizes
        --fs-heading-1: 2.4rem;
        --fs-heading-2: 1.8rem;
        --fs-heading-3: 1.6rem;
        --fs-sub-heading: 1.4rem;
        --fs-questionnaire-title: 3.2rem;
        --fs-results-title: 2.8rem;
        --fs-button: 1.6rem;
        --fs-text: 1.6rem;
        --fs-text-small: 1.4rem;
        --fs-likert-text: 1.4rem;
        --fs-linear-slider-step: 1.2rem;
        --fs-linear-flag: 1.9rem;
        --fs-search-button: 1.2rem;
        --fs-location-code: 1rem;
        --fs-environment: 1.3rem;
        --fs-info-tooltip: 1.3rem;
        --fs-select-all: 1.1rem;

        // Box shadow
        --box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, .05);
        --box-shadow-aside: 2px 2px 20px 0 rgba(0, 0, 0, .05);
        --box-shadow-aside-flipped: -2px 2px 20px 0 rgba(0, 0, 0, .05);
    }

    html, body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
        padding: 0;
        font-size: 62.5%;
        font-family: var(--font), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        background-color: var(--color-background);
        line-height: 1.5;
        -webkit-text-size-adjust: 100%;
        tab-size: 4;
        font-feature-settings: normal;
    }

    em {
        font-style: italic;
    }

    // NProgress navigation loader bar
    #nprogress {
        pointer-events: none;
    }

    #nprogress .bar {
        background: var(--color-primary);
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
    }

    // Styles for SwitchTransition
    .fade-enter {
        opacity: 0;
    }

    .fade-exit {
        opacity: 1;
    }

    .fade-enter-active {
        opacity: 1;
        transition: opacity .2s;
    }

    .fade-exit-active {
        opacity: 0;
        transition: opacity .2s;
    }
`;

export default GlobalStyle;