import {Text} from '../../../components/Text/Text';
import styled from 'styled-components';

const StyledResultParagraph = styled.div`
    border-bottom: 1px solid var(--color-toggle-unchecked);
    padding-bottom: 5px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
`;

const StyledText = styled(Text)`
    width: 50%;
`;

export const ResultParagraph = ({data}) => {
    return (
        <StyledResultParagraph>
            <StyledText $bold $noMargin>{data?.title}</StyledText>
            <StyledText $noMargin>{data?.text}</StyledText>
        </StyledResultParagraph>
    );
}