import React, {createRef, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {ReactComponent as GoToToday} from '../../assets/icons/go-to-today.svg';
import {Text} from '../Text/Text';
import {Heading3} from '../Heading/Heading';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import {getBorderColor} from './timelineHelpers';
import {TimelineAppointment} from './TimelineAppointment';
import {OutOfView} from './OutOfView';

dayjs.locale('nl');

const OuterTimeline = styled.div`
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: var(--box-shadow);
    
    &::before, &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        height: calc(100% - ${({$scrollBarHeight}) => $scrollBarHeight + "px"});
        width: 66px;
        pointer-events: none;
        transition: opacity .2s ease;
        z-index: 1;
    }

    &:before {
        left: 70px;
        background: linear-gradient(to right, rgb(255, 255, 255), transparent);
        opacity: ${({$showLeftGradient}) => $showLeftGradient ? 1 : 0};
    }
    
    &::after {
        right: 0;
        background: linear-gradient(to left, rgb(255, 255, 255), transparent);
        opacity: ${({$showRightGradient}) => $showRightGradient ? 1 : 0};
    }
`;

const StyledTimeline = styled.div`
    background-color: var(--color-white);
    width: 100%;
    overflow-x: auto;
`;

const InnerTimeline = styled.div`
    height: 100%;
    background: var(--color-white);
    display: flex;
    width: fit-content;
`;

const Content = styled.div`
    width: 100%;
    height: 100%;
    background: var(--color-white);
    display: flex;
`;

const GoToTodayWrapper = styled.div`
    width: 100%;
    padding: 9px;
    height: 100%;
    position: sticky;
    top: 0;
    left: 0;
    bottom: 0;
    background: var(--color-white);
    background: linear-gradient(0deg, var(--color-grey-10) 0%, var(--color-white) 100%);
`;

const GoToTodayButton = styled.button`
    cursor: pointer;
    background: none;
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
`;

const GoToTodayIcon = styled(GoToToday)`
    width: 25px;
    height: 25px;
`;

const MonthLabelWrapper = styled.div`
    height: 44px;
    display: flex;
    align-items: center;
    background: var(--color-white);
    background: linear-gradient(0deg, var(--color-grey-10) 0%, var(--color-white) 100%);
`;

const MonthLabel = styled(Heading3).attrs({
    $noMargin: true,
})`
    text-transform: capitalize;
    ${({$isCurrentMonth}) => $isCurrentMonth && "color: var(--color-primary);"};
`;

const Aside = styled.div`
    flex-shrink: 0;
    height: 100%;
    width: 70px;
    background: var(--color-white);
    position: sticky;
    top: 0;
    left: 0;
    bottom: 0;
    box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, .05);
    display: grid;
    grid-template-rows: 44px repeat(4, 58px);
    z-index: 1;
`;

const LaneBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    &:not(:last-of-type) {
        border-bottom: 2px solid var(--color-grey-20);
    }
`;

const LaneText = styled(Text).attrs({
    $noMargin: true,
    $bold: true,
    $align: "center"
})``;

const MonthBox = styled.div`
    &:first-of-type {
        padding-left: 20px;
    }
`;

const Weeks = styled.div`
    display: flex;
    height: calc(100% - 44px);
    gap: 20px;
    padding: 0 20px;
    border-left: 2px dashed ${({$isCurrentMonth}) => $isCurrentMonth ? "var(--color-primary)" : "var(--color-grey-20)"};
`;

const WeekGrid = styled.div`
    flex: 1;
    display: grid;
    grid-template-columns: repeat(${({$columns}) => $columns}, 1fr);
    gap: 20px;
`;

const ColumnGrid = styled.div`
    display: grid;
    grid-template-rows: repeat(4, 58px); // PSA, VS, PSO, Groep
    column-gap: 20px;
`;

const Appointment = styled.div`
    grid-row: ${({$rows}) => $rows};
    padding: 8px;
    position: relative;
`;

const NonContiguousAppointment = styled.div`
    grid-row: ${({$row}) => $row};
    padding: 8px;
    position: relative;
    
    &:first-of-type {
        &::after {
            content: "";
            position: absolute;
            bottom: -66px; // 58px = height of the row + 8px of NonContiguousAppointment padding
            left: 50%;
            transform: translateX(-50%);
            width: 2px;
            height: 74px; // 58px = height of the row + 16px (padding of NonContiguousAppointment up and above)
            background: ${({$category}) => getBorderColor($category)};
        }
    }
`;

export const Timeline = ({data}) => {
    const timelineRef = useRef(null);
    const currentMonthRef = useRef(null);
    const appointmentRefs = useRef({});

    const [showLeftGradient, setShowLeftGradient] = useState(false);
    const [showRightGradient, setShowRightGradient] = useState(false);
    const [scrollBarHeight, setScrollBarHeight] = useState(0);

    // On scroll check if appointments are outside the timeline based on width calculations
    const [outOfViewAppointments, setOutOfViewAppointments] = useState({ PSA: { left: 0, right: 0 }, VS: { left: 0, right: 0 }, PSO: { left: 0, right: 0 }, Groep: { left: 0, right: 0 } });
    const calculateOutOfViewAppointments = () => {
        const { scrollLeft, clientWidth } = timelineRef.current;
        const updatedOutOfView = { PSA: { left: 0, right: 0 }, VS: { left: 0, right: 0 }, PSO: { left: 0, right: 0 }, Groep: { left: 0, right: 0 } };

        Object.entries(appointmentRefs.current).forEach(([key, ref]) => {
            const appointmentElement = ref.current;
            if (appointmentElement) {
                const { offsetLeft, offsetWidth } = appointmentElement;
                const appointmentRight = offsetLeft + offsetWidth;

                // Check if appointment is out of view on the left or right side
                if (appointmentRight < scrollLeft + 70) { // Completely to the left side of the timeline + 70 (the width of Aside overlay)
                    const lanes = key.split('_').slice(0, -1); // Retrieve all lanes, except the appointment ID
                    lanes.forEach(lane => {
                        if (updatedOutOfView.hasOwnProperty(lane)) {
                            updatedOutOfView[lane].left += 1;
                        }
                    });
                } else if (offsetLeft > scrollLeft + clientWidth) { // Completely to the right side of the timeline
                    const lanes = key.split('_').slice(0, -1); // Retrieve all lanes, except the appointment ID
                    lanes.forEach(lane => {
                        if (updatedOutOfView.hasOwnProperty(lane)) {
                            updatedOutOfView[lane].right += 1;
                        }
                    });
                }
            }
        });

        setOutOfViewAppointments(updatedOutOfView);
    };

    // Conditionally show gradients and calculate when appointments are out of view
    const handleScroll = () => {
        const { scrollLeft, scrollWidth, clientWidth } = timelineRef.current;
        const showLeftGradient = scrollLeft > 0;
        const shouldShowRightGradient = scrollLeft < scrollWidth - clientWidth;
        setShowLeftGradient(showLeftGradient);
        setShowRightGradient(shouldShowRightGradient);
        calculateOutOfViewAppointments();
    };

    // Dynamically add scrollbar height for gradient overlay
    const calculateScrollbarHeight = () => {
        const { offsetHeight, clientHeight } = timelineRef.current;
        const calculatedScrollBarHeight = offsetHeight - clientHeight;
        setScrollBarHeight(calculatedScrollBarHeight);
    }

    // Initially and on click, scroll to the current month
    const scrollToCurrentMonth = (behavior = "auto") => {
        if (currentMonthRef.current && timelineRef.current) {
            const { offsetLeft } = currentMonthRef.current;
            timelineRef.current.scrollTo({
                left: offsetLeft - 250,
                behavior: behavior
            })
        }
    }

    useEffect(() => {
        const timeline = timelineRef.current;
        if (timeline) {
            calculateScrollbarHeight();
            timeline.addEventListener('scroll', handleScroll);
            handleScroll();
        }
        scrollToCurrentMonth();
        return () => timeline && timeline.removeEventListener('scroll', handleScroll);
        //eslint-disable-next-line
    }, []);

    // Map the rows to row numbers
    const laneRowMap = {
        PSA: 1,
        VS: 2,
        PSO: 3,
        Groep: 4
    };

    // Helper function to calculate grid row span (start and end row for spanning multiple lanes)
    const getGridRows = (lanes) => {
        const rows = lanes.map(lane => laneRowMap[lane]).sort((a, b) => a - b);
        return `${rows[0]} / ${rows[rows.length - 1] + 1}`;
    };

    // Helper function to check if rows are non-contiguous
    const hasNonContiguousRows = (lanes) => {
        const rows = lanes.map(lane => laneRowMap[lane]).sort((a, b) => a - b);

        // Check for any gaps between sorted rows
        for (let i = 1; i < rows.length; i++) {
            if (rows[i] !== rows[i - 1] + 1) {
                return true; // Non-contiguous found
            }
        }
        return false; // No gaps, all rows are contiguous
    };

    const getCurrentMonth = (months) => months.find(month => checkIsCurrentMonth(month));

    const checkIsCurrentMonth = (month) => {
        const currentDate = dayjs();
        const monthDate = dayjs(`${month.year}-${month.month}-01`);
        return currentDate.isSame(monthDate, 'month') && currentDate.isSame(monthDate, 'year');
    };

    const checkIsInThePast = (date) => {
        const currentDateTime = dayjs();
        const appointmentDateTime = dayjs(date);
        return appointmentDateTime.isBefore(currentDateTime);
    };

    // On click of OutOfView items, scroll to the left or right
    const handleOutOfViewScroll = (direction) => {
        if (timelineRef.current) {
            const currentScrollPosition = timelineRef.current.scrollLeft;
            const scrollAmount = direction === 'right' ? 200 : -200;

            timelineRef.current.scrollTo({
                left: Math.max(0, currentScrollPosition + scrollAmount),
                behavior: "smooth"
            });
        }
    };

    return (
        <OuterTimeline $showLeftGradient={showLeftGradient} $showRightGradient={showRightGradient} $scrollBarHeight={scrollBarHeight}>
            <StyledTimeline ref={timelineRef}>
                <InnerTimeline>
                    <Aside>
                        <GoToTodayWrapper>
                            {getCurrentMonth(data.months) &&
                                <GoToTodayButton type="button" onClick={() => scrollToCurrentMonth("smooth")}>
                                    <GoToTodayIcon />
                                </GoToTodayButton>
                            }
                        </GoToTodayWrapper>
                        <LaneBox><LaneText>PSA</LaneText></LaneBox>
                        <LaneBox><LaneText>VS</LaneText></LaneBox>
                        <LaneBox><LaneText>PSO</LaneText></LaneBox>
                        <LaneBox><LaneText>Groep</LaneText></LaneBox>
                    </Aside>

                    <Content>
                        {data?.months?.map((month, monthIndex) => {
                            const monthToDate = dayjs(`${month.year}-${month.month}-01`);
                            const formattedMonth = dayjs(monthToDate).format('MMMM');
                            const isCurrentMonth = checkIsCurrentMonth(month);

                            return (
                                <MonthBox key={monthIndex} ref={isCurrentMonth ? currentMonthRef : null}>
                                    <MonthLabelWrapper>
                                        <MonthLabel $isCurrentMonth={isCurrentMonth}>{formattedMonth}</MonthLabel>
                                    </MonthLabelWrapper>
                                    <Weeks $isCurrentMonth={isCurrentMonth}>
                                        {month.weeks.map((week, weekIndex) => (
                                            <WeekGrid key={weekIndex} $columns={week.columns.length}>
                                                {week.columns.map((column, columnIndex) => (
                                                    <ColumnGrid key={columnIndex}>
                                                        {column.appointments.map((appointment, appointmentIndex) => {
                                                            // Make sure the lanes are in the correct order (same order as the laneRowMap)
                                                            const sortedLanes = [...appointment.lanes].sort((a, b) => laneRowMap[a] - laneRowMap[b]);

                                                            const isNonContiguous = hasNonContiguousRows(sortedLanes);
                                                            const isInThePast = checkIsInThePast(appointment.end);
                                                            const isNotProcessed = (appointment.status === "Booked" && isInThePast && !appointment.processed);

                                                            if (isNonContiguous) {
                                                                return sortedLanes.map((lane, laneIndex) => {
                                                                    const appointmentKey = `${lane}_${appointment.id}`;
                                                                    appointmentRefs.current[appointmentKey] = createRef();

                                                                    return (
                                                                        <NonContiguousAppointment
                                                                            key={laneIndex}
                                                                            ref={appointmentRefs.current[appointmentKey]}
                                                                            $row={laneRowMap[lane]} // Position according to the lane row
                                                                            $category={appointment.category}
                                                                        >
                                                                            <TimelineAppointment appointment={appointment} isInThePast={isInThePast} isNotProcessed={isNotProcessed} />
                                                                        </NonContiguousAppointment>
                                                                    )
                                                                });
                                                            }

                                                            const appointmentKey = `${appointment.lanes.join('_')}_${appointment.id}`;
                                                            appointmentRefs.current[appointmentKey] = createRef();

                                                            return (
                                                                <Appointment
                                                                    key={appointmentIndex}
                                                                    ref={appointmentRefs.current[appointmentKey]}
                                                                    $rows={getGridRows(sortedLanes)}
                                                                >
                                                                    <TimelineAppointment appointment={appointment} isInThePast={isInThePast} isNotProcessed={isNotProcessed} />
                                                                </Appointment>
                                                            );
                                                        })}
                                                    </ColumnGrid>
                                                ))}
                                            </WeekGrid>
                                        ))}
                                    </Weeks>
                                </MonthBox>
                            );
                        })}
                    </Content>
                </InnerTimeline>
            </StyledTimeline>

            <OutOfView laneRowMap={laneRowMap} outOfViewAppointments={outOfViewAppointments} handleOutOfViewScroll={handleOutOfViewScroll} direction="left" />
            <OutOfView laneRowMap={laneRowMap} outOfViewAppointments={outOfViewAppointments} handleOutOfViewScroll={handleOutOfViewScroll} direction="right" />
        </OuterTimeline>
    );
}