import {useParams} from 'react-router-dom';
import {useOpenModal} from '../../../hooks/useOpenModal';
import {useEffect, useState} from 'react';
import {Statuses} from '../../../constants/enums';
import Api from '../../../api/Api';
import {Text} from '../../../components/Text/Text';
import styled from 'styled-components';
import {ReactComponent as Info} from '../../../assets/icons/info2.svg';
import ContentModal from '../../../components/Modal/ContentModal';
import {Heading2, Heading3} from '../../../components/Heading/Heading';
import {Spinner, SpinnerWrapper} from '../../../components/Spinner/Spinner';

const InfoIcon = styled(Info)`
    width: 20px;
    height: 20px;
    vertical-align: top;
    cursor: pointer;
    margin-left: 8px;
`;

const Card = styled.div`
    background-color: var(--color-white);
    border-radius: 16px;
    box-shadow: var(--box-shadow);
    padding: 24px;
    
    &:first-of-type {
        margin-bottom: 24px;
    }
`;

const Lane = styled.div`
    display: flex;
    gap: 30px;
    padding: 14px 0;
    
    &:first-of-type {
        margin-top: 20px;
    }
    
    &:last-of-type {
        padding-bottom: 0;
    }
    
    &:not(:last-of-type) {
        border-bottom: 1px solid rgba(var(--color-grey-20-rgb), .5);    
    }
`;

const Role = styled(Heading3).attrs({
    $noMargin: true,
})`
    flex-shrink: 0;
    flex-basis: 200px;
`;

const Names = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const Name = styled(Text).attrs({
    $noMargin: true,
})``;

const StyledSpinnerWrapper = styled(SpinnerWrapper)`
    min-height: 152px;
`;

export const CareTeam = () => {
    const {patientUUID} = useParams();
    const {isOpen, handleOpen, handleClose} = useOpenModal();
    const [status, setStatus] = useState(Statuses.LOADING);
    const [data, setData] = useState(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (isOpen) {
            if (Boolean(data)) setStatus(Statuses.REVALIDATING);

            const controller = new AbortController();

            Api.get(`patient/${patientUUID}/careteam`, (res) => {
                setData(res);
                setError(false);
            }, () => {
                setError(true);
            }, controller).then(() => setStatus(Statuses.IDLE));

            return () => {
                Api.abortRequest(controller);
            }
        }
        //eslint-disable-next-line
    }, [isOpen]);

    return (
        <>
            <Text $noMargin $bold>Behandelteam<InfoIcon onClick={handleOpen} /></Text>

            <ContentModal isOpen={isOpen} handleClose={handleClose}>
                <ContentModal.Header handleClose={handleClose}>Behandelteam</ContentModal.Header>

                <Card>
                    <Heading2 $noMargin>Huidig behandelteam</Heading2>

                    {status === Statuses.LOADING ? (
                        <StyledSpinnerWrapper>
                            <Spinner />
                        </StyledSpinnerWrapper>
                    ) : (
                        <>
                            <Lane>
                                <Role>Psychiater</Role>
                                <Names>
                                    {data?.current?.PSA?.length > 0 &&
                                        data?.current?.PSA?.map((item, index) => (
                                            <Name key={index}>{item}</Name>
                                        ))
                                    }
                                </Names>
                            </Lane>

                            <Lane>
                                <Role>Verpleegkundig specialist</Role>
                                <Names>
                                    {data?.current?.VS?.length > 0 &&
                                        data?.current?.VS?.map((item, index) => (
                                            <Name key={index}>{item}</Name>
                                        ))
                                    }
                                </Names>
                            </Lane>

                            <Lane>
                                <Role>Psycholoog</Role>
                                <Names>
                                    {data?.current?.PSO?.length > 0 &&
                                        data?.current?.PSO?.map((item, index) => (
                                            <Name key={index}>{item}</Name>
                                        ))
                                    }
                                </Names>
                            </Lane>
                        </>
                    )}
                </Card>

                <Card>
                    <Heading2 $noMargin>Oude behandelaren</Heading2>

                    {status === Statuses.LOADING ? (
                        <StyledSpinnerWrapper>
                            <Spinner />
                        </StyledSpinnerWrapper>
                    ) : (
                        <>
                            <Lane>
                                <Role>Psychiater</Role>
                                <Names>
                                    {data?.old?.PSA?.length > 0 &&
                                        data?.old?.PSA?.map((item, index) => (
                                            <Name key={index}>{item}</Name>
                                        ))
                                    }
                                </Names>
                            </Lane>

                            <Lane>
                                <Role>Verpleegkundig specialist</Role>
                                <Names>
                                    {data?.old?.VS?.length > 0 &&
                                        data?.old?.VS?.map((item, index) => (
                                            <Name key={index}>{item}</Name>
                                        ))
                                    }
                                </Names>
                            </Lane>

                            <Lane>
                                <Role>Psycholoog</Role>
                                <Names>
                                    {data?.old?.PSO?.length > 0 &&
                                        data?.old?.PSO?.map((item, index) => (
                                            <Name key={index}>{item}</Name>
                                        ))
                                    }
                                </Names>
                            </Lane>
                        </>
                    )}
                </Card>

                {error && <Text>Het behandelteam kan niet worden opgehaald, probeer het opnieuw.</Text>}
            </ContentModal>
        </>
    );
}