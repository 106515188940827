import {Button} from '../../../components/Button/Button';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Text} from '../../../components/Text/Text';
import ConfirmationModal from '../../../components/Modal/ConfirmationModal';
import {useFetcher, useParams} from 'react-router-dom';
import {Statuses} from '../../../constants/enums';

const StyledButton = styled(Button)`
    border: none;
    width: 100%;
    margin-top: 20px;
`;

const CancelButton = styled.button`
    background-color: transparent;
    color: var(--color-text-alpha-50);
    text-align: center;
    cursor: pointer;
    font-weight: var(--fw-bold);
    font-size: var(--fs-button);
    text-decoration: none;
    line-height: 29px;
    border: none;
    width: 100%;
    margin-top: 10px;
    
    &:hover {
        color: var(--color-text);
    }
`;

export const DeleteAppAccount = ({isOpen, handleClose}) => {
    const {patientUUID} = useParams();
    const fetcher = useFetcher();

    const [deleteStatus, setDeleteStatus] = useState(Statuses.IDLE);
    const [deleteError, setDeleteError] = useState(false);

    const handleDelete = () => {
        setDeleteStatus(Statuses.SUBMITTING);
        setDeleteError(false);

        const formData = new FormData();
        formData.append("intent", "delete-app-account");
        fetcher.submit(formData, { method: "DELETE", action: `/patient/${patientUUID}/personalia`})
    }

    // Fetcher callback
    useEffect(() => {
        if(fetcher?.state === "idle" && (fetcher?.data || fetcher?.data === "")) {
            setDeleteStatus(Statuses.IDLE);

            if(fetcher?.data?.error) return setDeleteError(true);

            handleClose();
        }
        //eslint-disable-next-line
    }, [fetcher]);

    const disabled = deleteStatus === Statuses.SUBMITTING;

    useEffect(() => {
        if(isOpen) setDeleteError(false);
    }, [isOpen]);

    return (
        <ConfirmationModal isOpen={isOpen} handleClose={handleClose}>
            <ConfirmationModal.Header>App account verwijderen</ConfirmationModal.Header>
            <Text $noMargin $align="center">Weet je zeker dat je het app account voor deze patiënt wil verwijderen? Je kunt te allen tijde weer een nieuw app account aanmaken en de patiënt uitnodigen.</Text>
            <StyledButton type="button" onClick={handleDelete} disabled={disabled} loading={disabled}>App account verwijderen</StyledButton>
            <CancelButton onClick={handleClose}>Annuleren</CancelButton>
            {deleteError && <Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}
        </ConfirmationModal>
    );
}