import PageHeadingContainer from "../../components/PageHeadingContainer/PageHeadingContainer";
import {Heading1} from "../../components/Heading/Heading";
import React, {useEffect, useRef, useState} from "react";
import {Outlet, useLoaderData, useLocation, useMatch, useNavigate, useRouteLoaderData} from "react-router-dom";
import styled from "styled-components";
import {AddReservationInvite} from "./Partials/AddReservationInvite";
import {fetchProtectedData, postData} from "../../api/fetch";
import {FilterButton} from "./Partials/FilterButton";
import {AppliedFilterList} from "../../components/AppliedFilters/AppliedFilterList";
import {checkRequiredAccount} from '../Root/Root';
import {Permissions} from '../../constants/enums';
import SegmentedControl from '../../components/SegmentedControl/SegmentedControl';
import {checkAccountPermissions} from '../../utils/helpers';
import {ExportButton} from "./Partials/ExportButton";

const PageHeadingInnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const Buttons = styled.div`
    display: flex;
    gap: 20px;
`;

const StyledSegmentedControl = styled(SegmentedControl)`
    margin-top: 40px;
`;

export async function waitingListAction({ request }) {
    let data = await request.json()
    let intent = data.intent
    switch (intent) {
        case "add-reservation-invite": {
            delete data.intent
            return await postData(request, `reservation/invite`, JSON.stringify(data))
        }
        default:
            return {"default": true}
    }
}

export async function waitingListLoader({ request }) {
    await checkRequiredAccount([Permissions.PORTAL_ACCESS_RESERVATIONS, Permissions.PATIENT_VIEW]);

    const healthInsurancePromise = fetchProtectedData(request, 'health-insurance');
    const emailTextPromise = fetchProtectedData(request, 'reservation/emailtext');
    const [healthInsuranceData, emailTextData] = await Promise.all([healthInsurancePromise, emailTextPromise]);

    return {healthInsuranceData, emailTextData}
}

const WaitingList = () => {
    const {account} = useRouteLoaderData("root");
    const {locationData} = useRouteLoaderData("portal");
    const {healthInsuranceData} = useLoaderData();
    const [selected, setSelected] = useState([]); // Lifted state to store checkboxes
    const navigate = useNavigate();
    const {pathname, search} = useLocation();
    const matchWaitingList = useMatch("/reservation/waiting-list")
    const matchInvitedList = useMatch("/reservation/waiting-list/invited")

    useEffect(() => {
        setSelected([]);
    }, [matchWaitingList]);

    return (
        <>
            <PageHeadingContainer $flexDirection="column" $alignItems='start'>
                <PageHeadingInnerContainer>
                    <Heading1>Uitnodigen reservering Diagnostiekdag</Heading1>
                    <Buttons>
                        {(checkAccountPermissions(account, Permissions.INVITE_CREATE) && Boolean(matchWaitingList)) &&
                            <AddReservationInvite
                                selected={selected}
                                onAfterSubmit={() => setSelected([])}
                            />
                        }
                        <ExportButton type={matchInvitedList ? "invited" : "waiting"}/>
                        <FilterButton
                            showInvitedFilters={Boolean(matchInvitedList)}
                            healthInsuranceData={healthInsuranceData}
                            locationData={locationData}

                            onAfterSubmit={() => setSelected([])}
                        />
                    </Buttons>
                </PageHeadingInnerContainer>
                <AppliedFilterList healthInsuranceData={healthInsuranceData} locationData={locationData} />
                <StyledSegmentedControl
                    name="notifications"
                    callback={(pathname) => {
                        const params = new URLSearchParams(search)
                        if (!pathname.includes("invited")) {
                            params.delete("invitedFrom")
                            params.delete("invitedUntil")
                        }

                        navigate(`${pathname}?${params ? params.toString() : ''}`, { preventScrollReset: true })
                    }}
                    defaultIndex={pathname.includes("invited") ? 1 : 0}
                    controlRef={useRef()}
                    segments={[
                        {
                            label: "Wachtlijst",
                            value: "/reservation/waiting-list",
                            ref: useRef()
                        },
                        {
                            label: "Uitgenodigd",
                            value: "/reservation/waiting-list/invited",
                            ref: useRef()
                        }
                    ]}
                />
            </PageHeadingContainer>

            <Outlet context={{selected, setSelected}} />
        </>
    )
}

export default WaitingList