import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {FormFieldWrapper} from '../../../components/FormFields/FormFields';
import {useSearchParams} from 'react-router-dom';
import {FilterButtonBase} from '../../../components/FilterButtonBase/FilterButtonBase';
import {monthOptions, weekdayOptions} from '../../../utils/dateOptions';
import {ControlledPortalSelectField} from '../../../components/PortalFormFields/PortalSelectField/ControlledPortalSelectField';

const StyledFormFieldWrapper = styled(FormFieldWrapper)`
    min-width: 0;
`;

export function FilterButton({locationData, onAfterSubmit}) {
    const [isOpen, setIsOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    // Set the unappliedFilters initially to current searchParams (retreived from searchParams
    const [unappliedFilters, setUnappliedFilters] = useState({
        weekday: searchParams.getAll("weekday"),
        month: searchParams.getAll("month"),
        location: searchParams.getAll("location"),
    });

    // Keep unappliedFilters correct when searchParams change (when deleting in AppliedFilterList)
    useEffect(() => {
        setUnappliedFilters({
            weekday: searchParams.getAll("weekday"),
            month: searchParams.getAll("month"),
            location: searchParams.getAll("location"),
        })
    }, [searchParams]);

    function handleUnappliedFiltersChange(key, value) {
        setUnappliedFilters({
            ...unappliedFilters,
            [key]: value
        })
    }

    function handleApply() {
        // Set search params optionally
        const newParams = {
            ...(unappliedFilters.weekday ? {weekday: unappliedFilters.weekday} : {}),
            ...(unappliedFilters.location ? {location: unappliedFilters.location} : {}),
            ...(unappliedFilters.month ? {month: unappliedFilters.month} : {}),
        }
        setSearchParams(newParams, { replace: true, preventScrollReset: true });
        onAfterSubmit();
        setIsOpen(false);
    }

    // Select fields options
    const locationFilterOptions = locationData?.map(item => ({value: item.id, label: item.name})) ?? [];

    return (
        <FilterButtonBase isOpen={isOpen} setIsOpen={setIsOpen} handleApply={handleApply}>
            <StyledFormFieldWrapper
                id="weekday"
                label="Weekdag"
            >
                <ControlledPortalSelectField
                    isMulti={true}
                    placeholder="Alle"
                    options={weekdayOptions}
                    onChange={(option) => handleUnappliedFiltersChange("weekday", option?.filter(o => o?.value?.length > 0).map(o => o.value))}
                    defaultValue={weekdayOptions?.filter(o => unappliedFilters?.weekday?.includes(o.value))}
                />
            </StyledFormFieldWrapper>

            <StyledFormFieldWrapper
                id="month"
                label="Maand"
            >
                <ControlledPortalSelectField
                    isMulti={true}
                    placeholder="Alle"
                    options={monthOptions}
                    onChange={(option) => handleUnappliedFiltersChange("month", option?.filter(o => o?.value?.length > 0).map(o => o.value))}
                    defaultValue={monthOptions?.filter(o => unappliedFilters?.month?.includes(o.value))}
                />
            </StyledFormFieldWrapper>

            <StyledFormFieldWrapper
                id="location"
                label="Locatie"
            >
                <ControlledPortalSelectField
                    isMulti={true}
                    placeholder="Alle"
                    options={locationFilterOptions}
                    onChange={(option) => handleUnappliedFiltersChange("location", option?.filter(o => o?.value?.length > 0).map(o => o.value))}
                    defaultValue={locationFilterOptions?.filter(o => unappliedFilters?.location?.includes(o.value))}
                />
            </StyledFormFieldWrapper>
        </FilterButtonBase>
    );
}