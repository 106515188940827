import styled from 'styled-components';
import {LocationButton} from '../LocationButton/LocationButton';
import {useFilterContext} from '../../contexts/FilterContext';
import {useMatches} from 'react-router-dom';
import React from 'react';
import {WhatsNewButton} from './WhatsNewButton';
import {doesMatchSome, getCurrentEnvironment} from '../../utils/helpers';

const StyledTopBar = styled.div`
    position: sticky;
    top: ${({$hasEnvironmentBar}) => $hasEnvironmentBar ? "20px" : "0"};
    left: 0;
    right: 0;
    z-index: 98;
    padding: 0 60px;
    background-color: var(--color-background);
`;

const Inner = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 2px solid var(--color-divider);
    padding: 15px 0;
`;

export function TopBar({locationData}) {
    const { locationFilter } = useFilterContext();
    const selectedLocation = locationData?.find(item => item.id === locationFilter);
    const currentEnvironment = getCurrentEnvironment();

    // Hide the location button on certain pages
    const matches = useMatches();
    const hideLocationButtonOnPages = [
        "/patient/:patientUUID",
        "/patient/search",
        "/patient/:patientUUID/afspraak-reserveren",
        "/reservation/waiting-list",
        "/reservation/reservations/:status"
    ]
    const shouldHideLocationButton = doesMatchSome(matches, hideLocationButtonOnPages)

    // TODO: Tijdelijk hele TopBar verbergen, bij implementatie What's New onderstaande line verwijderen.
    if(shouldHideLocationButton) return null;

    return (
        <StyledTopBar $hasEnvironmentBar={currentEnvironment.showBar}>
            <Inner>
                {!shouldHideLocationButton && (
                    <LocationButton
                        expanded={true}
                        data={locationData}
                        label={selectedLocation?.name ? selectedLocation.name : "Alle locaties"}
                        code={selectedLocation?.code ? selectedLocation.code : "Alle"}
                        selectedLocation={selectedLocation}
                    />
                )}

                {/* TODO: What's New */}
                {false &&
                    <WhatsNewButton />
                }
            </Inner>
        </StyledTopBar>
    );
}