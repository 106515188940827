import {Button} from '../../../components/Button/Button';
import styled from 'styled-components';
import {Spinner} from '../../../components/Spinner/Spinner';
import Api from '../../../api/Api';
import {useState} from 'react';
import {Statuses} from '../../../constants/enums';
import {useSearchParams} from 'react-router-dom';
import qs from 'qs';

const StyledExportButton = styled(Button)`
    background-color: var(--color-portal-button);
    padding: 8px 20px;
    color: var(--color-primary);
    min-width: 200px;

    &:hover { background-color: var(--color-portal-button-hover); }
`;

const StyledSpinner = styled(Spinner)`
    width: 16px;
    height: 16px;
    flex-shrink: 0;
`;

export const ExportButton = ({type}) => {
    const [status, setStatus] = useState(Statuses.IDLE);
    let [searchParams,] = useSearchParams();

    const handleClick = async (type) => {
        setStatus(Statuses.LOADING);
        const controller = new AbortController();

        const registeredFrom = searchParams.get("registeredFrom");
        const registeredUntil = searchParams.get("registeredUntil");
        const invitedFrom = searchParams.get("invitedFrom");
        const invitedUntil = searchParams.get("invitedUntil");
        const healthInsurance = searchParams.getAll("healthInsurance");
        const location = searchParams.getAll("location");
        const hasEmail = searchParams.get("hasEmail")

        const queryString = qs.stringify({
            ...(registeredFrom && {registeredFrom}),
            ...(registeredUntil && {registeredUntil}),
            ...(invitedFrom && {invitedFrom}),
            ...(invitedUntil && {invitedUntil}),
            ...(healthInsurance && {healthInsurance}),
            ...(location && {location}),
            ...(hasEmail && {hasEmail}),
        }, { arrayFormat: 'comma' });

        await Api.get(`reservation/patients/${type}/download${queryString ? `?${queryString}` : ""}`, (blob) => {
            // Create an url from the blob response and download it by simulating a click
            const blobUrl = window.URL.createObjectURL(blob);
            const hiddenLink = document.createElement("a");
            hiddenLink.href = blobUrl;
            hiddenLink.setAttribute("download", "uitgenodigde-patient.csv");
            document.body.appendChild(hiddenLink);
            hiddenLink.click();
            hiddenLink.remove();
        }, null, controller, "blob")

        setStatus(Statuses.IDLE);
    };

    return (
        <>
            <StyledExportButton
                type="button"
                suffix={<></>}
                disabled={status === Statuses.LOADING}
                onClick={() => handleClick(type)}
            >
                Export naar CSV
                {status === Statuses.LOADING && <StyledSpinner />}
            </StyledExportButton>
        </>
    );
}