import {checkRequiredAccount} from '../Root/Root';
import {InfoList, InfoListItem, InfoListWrapper} from '../../components/InfoList/InfoList';
import {useRouteLoaderData} from 'react-router-dom';
import styled from 'styled-components';
import {Alert} from '../../components/Alert/Alert';
import {CareTeam} from './Partials/CareTeam';
import {Permissions} from '../../constants/enums';
import {joinWithCommaAndAmpersand} from '../../utils/helpers';

const StyledInfoListWrapper = styled(InfoListWrapper)`
    grid-template-columns: 1fr 1fr 0.75fr 1fr;
`;

export async function summaryLoader({request, params}) {
    const {account} = await checkRequiredAccount(Permissions.PORTAL_ACCESS_PATIENT_SUMMARY);

    return account;
}

const Summary = () => {
    const {patientData} = useRouteLoaderData("patient");

    return (
        <>
            <StyledInfoListWrapper>
                <InfoList>
                    <InfoListItem label="Locatie">{patientData?.location?.[0] && patientData.location[0]}</InfoListItem>
                    <InfoListItem label="Comorbiditeit">{patientData.comorbidity}</InfoListItem>
                    <InfoListItem label="Behandeltraject">{joinWithCommaAndAmpersand(patientData.carePlan)}</InfoListItem>
                    <CareTeam />
                </InfoList>
                <InfoList>
                    <InfoListItem label="Primaire diagnose">{patientData.primaryDiagnosis}</InfoListItem>
                    <InfoListItem label="Zorgvraagtype">{patientData.careDemandType}</InfoListItem>
                    <InfoListItem label="Start behandeling">{patientData.startCarePlan}</InfoListItem>
                </InfoList>
                <InfoList>
                    <InfoListItem label="Hartslag">{patientData.heartRate}</InfoListItem>
                    <InfoListItem label="Bloeddruk">{patientData.bloodPressure}</InfoListItem>
                </InfoList>
                <InfoList>
                    <InfoListItem label="Aantal no-shows" wrapInText={false}>{patientData.noShows && <Alert>{patientData.noShows}</Alert>}</InfoListItem>
                    <InfoListItem label="Medicatie">{patientData.medication}</InfoListItem>
                    <InfoListItem label="Medische bijzonderheden">{patientData.medicalDetails}</InfoListItem>
                </InfoList>
            </StyledInfoListWrapper>
        </>
    );
}

export default Summary;