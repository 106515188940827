import styled from "styled-components";
import {ChevronIcon} from "./Table/Table";
import React, {forwardRef} from "react";
import {useSearchParams} from 'react-router-dom';
import {Text} from './Text/Text';

const PaginationContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    ${({$position}) => $position === "top" ? "margin-bottom: 40px;" : "margin-top: 40px;"};
`;

const Inner = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Box = styled.div`
    flex: 1;
`;

const PageButton = styled.button`
    width: 32px;
    height: 32px;
    margin: 0 4px;
    text-align: center;
    cursor: pointer;
    font-size: var(--fs-button);
    font-weight: var(--fw-medium);
    background-color: ${({ active }) => (active ? 'rgba(48, 66, 150, 0.1)' : 'transparent')};
    color: ${({ active }) => (active ? 'rgba(48, 66, 150, 1)' : '#000')};
    border: ${({ active }) => (active ? '2px solid rgba(48, 66, 150, 1)' : 'none')};
    border-radius: 4px;
    padding: 0;
`;

const PrevNextButton = styled.button`
    cursor: pointer;
    background-color: ${({ disabled }) => (disabled ? 'rgba(45, 42, 38, 0.12)' : 'rgba(48, 66, 150, 1)')};
    transform: ${({$isPrevious }) => ($isPrevious && 'rotate(180deg)')};
    border-radius: 4px;
    border: none;
    padding: 0;
`;

const Chevron = styled(ChevronIcon)`
    path { fill: ${props => (props.disabled ? 'rgba(45, 42, 38, 0.35)' : 'white')}; }
`;

const EllipsisButton = styled.button`
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: var(--fs-button);
    font-weight: var(--fw-medium);
    background-color: transparent;
    color: #000;
    border: none;
`;

const Pagination = forwardRef((props, ref) => {
    const {searchResults, onPageChange, ...otherProps} = props;

    let [searchParams, setSearchParams] = useSearchParams();
    const currentPage = Boolean(searchParams.get("page")) ? parseInt(searchParams.get("page")) : 1;
    const maxVisiblePages = 7; // Number of pages to show before using ellipsis

    const handlePageChange = (page) => {
        searchParams.set("page", page);
        setSearchParams(searchParams, { replace: true, preventScrollReset: true });
        onPageChange && onPageChange(page);
    };

    const renderPageNumbers = () => {
        const totalPages = searchResults?.pages;
        const pageNumbers = [];

        // If there are 7 pages or fewer, display all pages as separate buttons
        if (totalPages <= maxVisiblePages) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }

            return pageNumbers.map((page) => (
                <PageButton
                    key={page}
                    onClick={() => handlePageChange(page)}
                    active={currentPage === page}
                >
                    {page}
                </PageButton>
            ));
        }

        // Else there are more than 7 pages
        let startPage, endPage;

        if(currentPage <= 4) {
            // If the current page <= 4, display the first 5 pages
            startPage = 1;
            endPage = 5;
        } else if (currentPage >= totalPages - 3) {
            // If the current page is at the last pages, display the last 5 pages
            startPage = totalPages - 4;
            endPage = totalPages;
        } else {
            // Else display 3 pages in center
            startPage = currentPage - 1;
            endPage = currentPage + 1;
        }

        // Fill the pageNumbers array to display the pages in the correct format
        if (startPage > 1) {
            pageNumbers.push(1);
            if (startPage > 2) {
                pageNumbers.push('ellipsis-start');
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pageNumbers.push('ellipsis-end');
            }
            pageNumbers.push(totalPages);
        }

        return pageNumbers.map(page => {
            if (page === 'ellipsis-start' || page === 'ellipsis-end') {
                return (
                    <EllipsisButton
                        key={page}
                        onClick={() => handlePageChange(page === 'ellipsis-start'
                            ? Math.max(1, currentPage - 5)
                            : Math.min(totalPages, currentPage + 5)
                        )}
                    >
                        &hellip;
                    </EllipsisButton>
                );
            }
            return (
                <PageButton
                    key={page}
                    onClick={() => handlePageChange(page)}
                    active={currentPage === page}
                >
                    {page}
                </PageButton>
            );
        });
    };

    // Format the results in string format "X - Y van Z resultaten"
    function getResultsAmount(searchResults, currentPage) {
        if (!searchResults || !searchResults?.total || !searchResults?.limit) return null;
        const { total, limit } = searchResults;
        const start = (currentPage - 1) * limit + 1;
        const end = Math.min(currentPage * limit, total);

        return `${start} - ${end} van ${total} resultaten`;
    }

    return (
        <PaginationContainer ref={ref} {...otherProps}>
            <Box>
                <Text $small>{getResultsAmount(searchResults, currentPage)}</Text>
            </Box>
            <Inner>
                <PrevNextButton
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1 || searchResults?.prev === null}
                    $isPrevious={true}
                >
                    <Chevron disabled={currentPage === 1 || searchResults?.prev === null} />
                </PrevNextButton>
                {renderPageNumbers()}
                <PrevNextButton
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === searchResults?.pages || searchResults?.next === null}
                >
                    <Chevron disabled={currentPage === searchResults?.pages || searchResults?.next === null} />
                </PrevNextButton>
            </Inner>
            <Box />
        </PaginationContainer>
    );
});

export default Pagination