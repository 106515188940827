import styled from 'styled-components';
import {ReactComponent as ExclamationMark} from '../../assets/icons/exclamation-mark2.svg';
import {ReactComponent as NoShow} from '../../assets/icons/no-show.svg';
import React from 'react';

const StyledTimelineFlag = styled.div`
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 999px;
    background-color: ${({$isNotProcessed}) => $isNotProcessed ? "var(--color-unprocessed)" : "var(--color-no-show)"};
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
`;

const NoShowIcon = styled(NoShow)`
    width: 16px;
    height: 16px;
`;

const ExclamationMarkIcon = styled(ExclamationMark)`
    width: 16px;
    height: 16px;
`;

export const TimelineFlag = ({type = "notProcessed"}) => {
    return (
        <StyledTimelineFlag $isNotProcessed={type === "notProcessed"}>
            {type === "notProcessed" ? (
                <ExclamationMarkIcon />
            ) : (
                <NoShowIcon />
            )}
        </StyledTimelineFlag>
    );
}